import React, { HTMLAttributes, useState, forwardRef } from 'react';
import { Input } from "@chakra-ui/react";
import ReactDatePicker from 'react-datepicker';
import { et } from 'date-fns/locale/et';
import { fi } from 'date-fns/locale/fi';
import { ru } from 'date-fns/locale/ru';
import { enUS } from 'date-fns/locale/en-US';
import 'react-datepicker/dist/react-datepicker.css';
import {useTranslation} from "next-i18next";

interface Props {
  isClearable?: boolean;
  locale: string;
  onChange: (date: Date) => any;
  selectedDate: Date | undefined;
  showPopperArrow?: boolean;
}

const CustomInput = forwardRef(({ value, onClick }, ref) => {
  const {t} = useTranslation("landing");
  return (
    <Input pl={10} ref={ref} value={value} onClick={onClick} readOnly size="lg" bg="white" type="text" placeholder={t("landing:date")} />
  )
});

CustomInput.displayName = "CustomInput";

const DatePicker = ({
  onChange,
  selectedDate,
  locale,
  isClearable = false,
  showPopperArrow = false,
  placeholder,
  ...props
}: Props & HTMLAttributes<HTMLElement>) => {
  let dateLocale = et;
  switch (locale) {
    case 'et':
      dateLocale = et;
      break;
    case 'fi':
      dateLocale = fi;
      break;
    case 'ru':
      dateLocale = ru;
      break;
    default:
      dateLocale = enUS;
  }

  return (
    <ReactDatePicker
      selected={selectedDate}
      onChange={onChange}
      isClearable={isClearable}
      customInput={<CustomInput />}
      dateFormat="dd-MM-YYYY"
      showPopperArrow={showPopperArrow}
      locale={dateLocale}
      {...props}
    />
  );
};

export default DatePicker;
